import { render, staticRenderFns } from "./AppMain.vue?vue&type=template&id=58b2e7b5&scoped=true&"
var script = {}
import style0 from "./AppMain.vue?vue&type=style&index=0&id=58b2e7b5&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "58b2e7b5",
  null
  
)

export default component.exports