<template>
  <div id="layout">
    <navbar></navbar>
    <app-main></app-main>
  </div>
</template>

<script>
import Navbar from './NavBar'
import AppMain from './AppMain'

export default {
  name: 'Layout',
  data () {
    return {
    }
  },
  components: {
    Navbar,
    AppMain
  },
  async created () {
  },
  methods: {
  }
}
</script>
<style lang="less" scoped>
#layout {
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  overflow-y: auto;
  overflow-x: hidden;
}
</style>
